import React, {useState} from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PrimaryButton from "../components/Button/PrimaryButton"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { v4 } from "uuid"
import {
  Section,
  SectionTitle,
  SectionLead,
  MarkdownContent,
  BreadCrumb,
  MainPanel,
  LeftPannel,
  RightPannel,
} from "../components/Section"
import LikeProductCarousel from "../components/LikeProductCarousel"
import QuotePopup from "../components/QuotePopup"
import CallNow from "../components/CallNow"
import BreakpointUp from "../components/Media/BreakpointUp"
import PrimaryForm from "../components/PrimaryForm"
import ListArrow from "../images/BulletIcon.svg"
import MapStyle from "../components/MapStyle/GaragesMap"
import LocationStyle from "../components/LocationStyle"
// import StateSale from "../components/StateSale"

const ListAreas = styled.ul`
  list-style: none;
  margin: 0 0 30px;
  padding: 0;
  column-count: 2;
  column-fill: balance;
  ${BreakpointUp.sm`
        column-count: 3;
    `}
  ${BreakpointUp.lg`
        column-count: 4;
    `}
`
const ListItem = styled.li`
  padding-left: 20px;
  vertical-align: middle;
  margin: 0;
  line-height: 26px;
  + li {
    margin-top: 10px;
  }
  &:before {
    content: url(${ListArrow});
    display: inline-block;
    margin-left: -20px;
    width: 20px;
    vertical-align: top;
  }
  a {
    color: #000;
    font-weight: 700;
    line-height: 26px;
    display: inline-block;
    &:hover {
      color: #fc0002;
    }
  }
`
const LeftContent = styled.div`
  & p {
    @media (min-width: 992px) {
      font-size: 18px;
      line-height: 32px;
      margin-bottom: 40px;
    }
    > a{
      &:hover{
        text-decoration:underline;
      }
    }
  }
`
const SectionEditor = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  align-items: center;
  justify-content: space-between;
`
const SectionEditorLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    `}
`
const SectionEditorRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
        flex: 0 0 50%;
        max-width: 50%;
    `}
  & p {
    ${BreakpointUp.lg`
            font-size:20px;
            line-height:38px;
        `}
  }
`
const LoadMore = styled.button`
  display:block;
  margin:0 auto;
`

const State = ({ data, location, pageContext: { category } }) => {
  const [ limit, Setlimit ] = useState(20);
  const [isVisibleQuote, setQuoteVisible] = useState(false)

  const pageData = data.contentfulState
  const productCategory = category
  const relatedProductsData = []
  
  data.allContentfulProduct.edges.map((product) => {
   if (product.node.productCategory.name.toLowerCase() === productCategory.split("-").join(" ")) {
     relatedProductsData.push(product.node)
   }
 })

  if (typeof window !== `undefined`) {
    if (isVisibleQuote === true) {
      document.body.classList.add("modal-open")
    } else {
      document.body.classList.remove("modal-open")
    }
  }
  
  return (
    <Layout location={location}>
      <Seo
        title={`Garages ${pageData.name} | Metal Garages ${pageData.name}, ${pageData.abbreviation} | Prefab Steel Garage Kits ${pageData.abbreviation}`}
        description={`Find Metal garages ${pageData.name}, prefab metal garages ${pageData.name}. Get high quality, economical, durable metal garages in ${pageData.name}, ${pageData.abbreviation} as ever when you buy from Coast to Coast Carports. Order Now!`}
      />
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <span>{pageData.name}</span>
        </div>
      </BreadCrumb>
      <Section
        pt="215px"
        pb="90px"
        xpt="120px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-left top-0 overflow-initial"
      >
        <div className="container">
          <MainPanel>
            <LeftPannel>
              <LeftContent>
                <h1>{`Metal Garages ${pageData.name}`}</h1>
                <p>
                  A metal garage is a smart investment you can make to safely
                  and securely store your car, truck, motorcycle, RV, boat,
                  tractor, lawn equipment, or anything else that needs
                  protecting and safekeeping! Quality-built metal garages are
                  available from Coast To Coast Carports, Inc. throughout{" "}
                  {pageData.name}. Our garages are the best in{" "}
                  {pageData.abbreviation}, AND they come at the right price!
                </p>
                <p>We provide a wide range of high-quality metal garages in {pageData.name}, including one-car garages, two-car garages, three-car garages, RV garages, and more. These garages in {pageData.name} are durable, strong, and robust. They can withstand heavy snowfall, wind, and rain. Also, they are resistant to a range of threats and even protect your vehicles from harsh UV rays. Galvanized steel panels provide strength to steel garages, making them stand for generations. You can use the metal garage as a guest room, storage space, hobby room, workshop, auto shop, and more. Need a garage today? Call our metal building specialist today at <a href="tel:8666817846" aria-label="(866) 681-7846">(866) 681-7846</a> and order the steel garage you have always wanted. Coast to Coast Carports has you covered for all your garage needs. </p>
                <h2>Uses of Metal Garages in {pageData.name}</h2>
                <h3>Must-have Protection for All Your Vehicles</h3>
                <p>
                  Looking for the best option to house your vehicles, equipment,
                  and other valuable items? Although our name is Coast To Coast
                  Carports, we are proud to offer much more than just carports.
                  If it’s a metal framed-and-roofed structure you need, we can
                  do it! Coast To Coast Carports is one of the leading suppliers
                  of metal buildings in the US, with four manufacturing
                  facilities dedicated to producing some of the finest metal
                  structures you can find. From metal carports to garages to
                  barns to commercial warehouses, we can take care of you. You
                  can also choose from either front or side-entry options for
                  your garage, depending on the layout of your install site and
                  on your convenience and preferences. Each of our steel garages
                  are fabricated with galvanized steel tubing and sheeting,
                  backed with a 20-year rust-proof warranty. No project is too
                  big or too small for our friendly team of experts!
                </p>
              </LeftContent>
            </LeftPannel>
            <RightPannel>
              <div className="isSticky">
              {/* {(pageData.abbreviation === "AR" || pageData.abbreviation === "IL" || pageData.abbreviation === "NM" || pageData.abbreviation === "CO" || pageData.abbreviation === "ID") && <StateSale state={pageData.abbreviation} /> } */}
                <PrimaryForm states={data.allContentfulState.edges}  location = {location} />
              </div>
            </RightPannel>
          </MainPanel>
        </div>
      </Section>
      <Section pt="0" pb="90px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <MapStyle data={pageData.locationsForMainBuildingCategories} />
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container">
          <SectionTitle mb="30px">Explore {pageData.name}’s Metal Garages</SectionTitle>
        </div>
        <LikeProductCarousel relatedProductsData={relatedProductsData} openQuoteModal={() => setQuoteVisible(true)} />
      </Section>
      {(pageData.abbreviation === "ID" || pageData.abbreviation === "TX" || pageData.abbreviation === "TN" || pageData.abbreviation === "OR" ) &&  
        <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#DDE4F0">
          <div className="container">
            <LocationStyle state={pageData.name} />
          </div>
        </Section>
      }
      <Section pt="90px" pb="90px" xpt="30px" xpb="60px" bgColor="#F2F4F9">
        <div className="container">
          <SectionTitle textAlign="left" maxWidth="910px">
            Roof Style Options For Your {pageData.name} Steel Garages
          </SectionTitle>
          <MarkdownContent maxWidth="910px" mb="30px" textAlign="left">
            <p>
              Choose from the following three roof styles for your{" "}
              {pageData.name}metal garage from Coast To Coast Carports
            </p>
            <h3>Regular Style Metal Garages</h3>
            <p>
              Our Regular Style Metal Garages have curved corners and a simple
              look, making them a popular choice among our {pageData.name}{" "}
              customers. Constructed with galvanized steel sheeting and tubing,
              these metal garages are made to last. We offer customizable door
              and access systems to ensure your garage is secure, and we have
              many color options and combinations to match your preferences.
              Delivery is free, and our experts can install on pretty much any
              level surface, from ground to gravel to cement or asphalt. Check
              out our product list to see some examples of our popular regular
              style garage designs.
            </p>

            <h3>A-Frame Horizontal Garages</h3>
            <p>
              Also known as boxed-eave metal garages, our A-frame horizontal
              garages are an easy and affordable way to match the look of your
              home or other existing structure. We offer multiple customization
              options including rollup garage doors, walk-in doors, windows,
              insulation, and more! Our A-frame horizontal garages do more than
              just provide functional storage space; they look great, and they
              can also serve to increase the overall value of your property.{" "}
            </p>

            <h3>A-Frame Vertical Garages</h3>
            <p>
              Coast to Coast Carports provides A-frame vertical garages as our
              premium choice for strength and stability in an{" "}
              {pageData.abbreviation} steel garage. The vertical roof has more
              reinforcement than other roof styles, and its vertical design
              allows water, snow to run off the roof more effortlessly, helping
              to prevent heavy accumulation. You can also opt for matching
              vertical sides and ends for an even better building. An A-frame
              vertical garage is our most-highly recommended option to best
              withstand the extremes of {pageData.name} weather.
            </p>
          </MarkdownContent>
        </div>
      </Section>
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container">
          <SectionEditor>
            <SectionEditorLeft>
              <h2>
                Why Choose Coast to Coast for Metal Garages in {pageData.name}:{" "}
              </h2>
            </SectionEditorLeft>
            <SectionEditorRight>
              <p>
                From our local manufacturing facilities in AR, TX, ID, and OR,
                Coast To Coast Carports stands ready to take care of all your{" "}
                {pageData.name} metal garage needs. We can deliver and install
                your steel garage anywhere in {pageData.abbreviation}, and we’ll
                do the job right for you the first time, and every time! Let us
                help you with the safe, secure metal shelter you need!
              </p>
              <p>
                Coast To Coast Carports can construct and install everything
                from carports to RV covers to sheds to garages to entire storage
                units. We’ve been in business since 2001, helping customers in{" "}
                {pageData.name} and across the country to get prefab,
                custom-engineered metal structures to meet any residential,
                commercial, or agricultural needs. Reach out to us at Coast To
                Coast Carports today, and let us show you why we’re the best in{" "}
                {pageData.abbreviation}!
              </p>
            </SectionEditorRight>
          </SectionEditor>
        </div>
      </Section>
      {/* for buid */}
      <Section pt="90px" pb="90px" xpt="60px" xpb="60px" bgColor="#fff">
        <div className="container">
          <SectionTitle mb="0">Areas We Serve In {pageData.name}</SectionTitle>
          <SectionLead mb="40px" maxWidth="900px">
            We cover all locations throughout the state of {pageData.name},
            including:
          </SectionLead>
          <ListAreas>
          {pageData.cities.slice(0, limit).map(item => {              
              return (
                <ListItem key={v4()}>
                  {item.content}
                </ListItem>
              )
            })}
          </ListAreas>
          {limit !== pageData.cities.length && 
          
            <LoadMore onClick={()=> Setlimit(pageData.cities.length)}><PrimaryButton text="Load More" /></LoadMore>
          }
        </div>
      </Section>
      <CallNow />
      <QuotePopup
        isVisible={isVisibleQuote}
        location={location}
        onClose={() => setQuoteVisible(false)}
      />
    </Layout>
  )
}

export default State

export const pageQuery = graphql`
  query GaragesStateLandingQuery($id: String!, $name: String!) {
    contentfulState(id: { eq: $id }) {
      name
      abbreviation
      cities {
        content
      }
      locationsForMainBuildingCategories {
        metalGarageAddress
        metalGarageContactNumber
        metalGarageLocationLink
        metalGarageMapEmbedLink {
          metalGarageMapEmbedLink
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
          locationsForMainBuildingCategories {
            metalGarageAddress
            metalGarageContactNumber
            metalGarageLocationLink
            metalGarageMapEmbedLink {
              metalGarageMapEmbedLink
            }
          }
        }
      }
    }
    allContentfulProduct(
      filter: {installationStates: {elemMatch: {name: {glob: $name}}}}
    ) {
      edges {
        node {
          productName
          productSku
          productName
          url
          productImages {
            title
            gatsbyImageData
          }
          productCategory {
            name
          }
          width
          height
          length
          price
        }
      }
    }
  }
`
